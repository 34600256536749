.Events {
    width: 100%;
    /* height: 55vh; */
    max-height: max-content;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    padding:20px 10px 10px 20px;
    background-image: url( https://www.manufosela.es/shooting_stars/stars_5.png);
  }
  
  .wrapper {
    display: flex;
    width: 90%;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .card {
    width: 280px;
    height: 360px;
    border-radius: 15px;
    padding: 1.5rem;
    margin:20px;
    background: white;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }
  .card:hover {
    transform: translateY(20px);
  }
  .card:hover:before {
    opacity: 1;
  }
  .card:hover .info {
    opacity: 1;
    transform: translateY(0px);
  }
  .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  .card img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  .card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
  }
  .card .info h1 {
    margin: 0px;
  }
  .card .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
  }
  .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .card .info button:hover {
    background: dodgerblue;
    color: white;
  }