.Timeline{
     /* background-color: #0b1e38 !important; */
     background-image: url( https://www.manufosela.es/shooting_stars/stars_5.png);
     color:"#0b1e38"; 
     font-size: 20px;
     font-weight: 800;
     align-items: center;
     position:relative;
}
.vertical-timeline-element-date{
     color:#00b69d
}

.vertical-timeline{
    
          -webkit-filter: blur(5px);
          -moz-filter: blur(5px);
          -o-filter: blur(5px);
          -ms-filter: blur(5px);
          filter: blur(5px);
         
          background-color: #ccc;
        
}