.Team{
    /* background-color: #0b1e38; */
    /* background-color: #0b1e38; */
    padding-top: 100px;
    background-image: url( https://www.manufosela.es/shooting_stars/stars_5.png);
    color:transparent; 
    
}
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

.Team .page-wrapper {
  font-family: 'Lato', sans-serif;
  min-height: 100vh;
  /* background-color: #F5F7FA; */
  /* background-color: #F5F7FA; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-evenly;
  padding-left: calc(5% - 16px);
  padding-right: calc(5% - 16px);
  /* padding: 0 5%; */
  /* padding: 0 5%; */
}

.Team .profile-box {
  background: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(47,83,151,0.10);
  border-radius: 3px;
  margin: 16px 16px;
  padding: 16px 16px;
  width: 26%;
  text-align: center;
  transition: 300ms;
  flex-grow: 1;
  max-width:220px;
}

.Team .profile-box:hover {
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  /* box-shadow: 0 2px 6px 0 rgba(47,83,151,0.40); */
  box-shadow: 0 0 20px 9px rgba(0,0,0,0.03);
}

.Team img {
  width: 100%;
  height: 199px;
  border-radius: 100px;
  box-shadow: 0 2px 6px 0 rgba(47,83,151,0.10);
}


.Team h3 {
  font-weight: 1000;
  font-size: 18px;
  margin-bottom: 0;
  color: #00b69d;
}

.Team h4 {
  transition: 500ms;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
  color: #00b69d;
  cursor: pointer;
}

.Team h4:hover {
  color: #507BFC;
  text-decoration: underline;
}

#assign,
#view {
  width: 50%;
  font-size: 14px;
  color: #ffffff;
  font-weight: 300;
  padding: 8px 0;
  border-radius: 100px;
  transition: 300ms;
  cursor: pointer;
}

#assign:hover,
#view:hover {
  background-color: #507BFC;
  color: white;
  box-shadow: 0 10px 20px 0 rgba(80,123,252,0.50);
}

#assign {
  float: left;
}

#view {
  float: right;
}

.Team i {
  margin-right: 8px;
}


