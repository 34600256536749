@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
.Gallery {
  /* text-align: center; */
  /* background-image: url("https://i.pinimg.com/564x/92/7f/e6/927fe675092970cc89241207067daa23.jpg"); */
  /* background-image: url(   https://www.manufosela.es/shooting_stars/hori.png), url( https://www.manufosela.es/shooting_stars/stars_5.png); */

 
  /* margin: 0 80px 0 80px; */
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  height: 100%;
}
.Gallery>p{
  position:relative;
  /* font-size:10vw; */
  font-family: 'Dancing Script', cursive;
  letter-spacing:3%;
  color:white;
  width:100%;
  text-align:center;
  -webkit-box-reflect: below 1px linear-gradient(transparent,white);
    line-height:.7em;
  outline:none;
  animation : animate 5s linear infinite alternate-reverse;
  padding-top: 5%;
}




@keyframes animate {
  30%{
    color:white;
    text-shadow:none;
  }
  50%{
    text-shadow: 0 0 5px white,
    0 0 40px white,
    0 0 80px white,
    0 0 160px white
    0 0 20px white,
  }
  
  100%{
    text-shadow: 0 0 5px white,
      0 0 20px cyan,
      0 0 40px cyan,
      0 0 80px cyan,
      0 0 160px cyan
  }
}


#layout { 
  color:transparent; 
  width:100%;
  background-image: url( https://www.manufosela.es/shooting_stars/stars_5.png);
  background-repeat: repeat-x, repeat-x repeat-y;
  transform:translate3D(0em, 0em, 0);
  animation: stars 21s ease;
  padding-bottom: 50px; 
  
}
.stars {
  z-index: 0;
  position: absolute;
  transform-style: preserve-3d;
}
