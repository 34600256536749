@import url("https://fonts.googleapis.com/css?family=Caveat|Righteous&display=swap");

#home {
    margin: 0;
    padding: 0;
    background: url(./images/back.jpg);
    background-repeat: no-repeat;
     height: 110vh;
    background-size:cover;
    width:100%;
    overflow: hidden;
    margin-top:-170px;
    line-height: 1.5;
    min-height: 100vh;
    
    display: grid;
    grid-template-rows: min-content 1fr;
    
    overflow-x: hidden;
}

/* button.mv1 {
    position: absolute;
    background: none;
    color: #35e5fd;
    font-size: 18px;
    border: 2px solid #35e5fd;
    margin-top: -160px; 
    margin-left: -180px;
    border-radius: 40px;
    height: 50px;
    width: 150px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    cursor: pointer;
}  */

button.mv2 {
   position: absolute;
   background: none;
   color: #35e5fd;
   font-size: 18px;
   font-weight: 500;
   border: 2px solid #35e5fd;
   margin-top: -21%; 
   margin-left: -75px;
   border-radius: 40px;
   height: 50px;
   width: 150px;
   -webkit-border-radius: 40px;
   -moz-border-radius: 40px;
   -ms-border-radius: 40px;
   -o-border-radius: 40px;
   cursor: pointer;
  color: #fff;
  background-image: linear-gradient(45deg, #35e5fd 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out;
}
.mv2:hover {
  background-position: 0;
} 
.p-1 {
    width: 150px;
    margin-bottom: 150%;
    margin-left: -116%;
    animation: rotate 50s linear infinite;
}
@keyframes rotate{
    to{
        transform: rotate(360deg);
    }
    from{
        transform: rotate(-360deg);
    }
}

.jscop{
    text-align: center;
    font-size: 90px;
    text-transform: uppercase;
     color: #fcfcfc;
    /* font-family: system-ui; */
    letter-spacing: 3px;
    word-spacing: 5px;
    /* animation: blink 2s linear infinite; */
     position: absolute;
    margin-bottom: 32%;
    background-color: transparent;
}
.dates{
    font-size: 35px;
    font-weight: 500;
}
.landing__Heading{
    /* height:15em; */
    margin-bottom:-10%;
    font-family: "Righteous", cursive;
    background: url("https://media.giphy.com/media/FE0WTM8BG754I/giphy.gif") center center no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 10rem;
    letter-spacing: 10px;
    background-size: cover;
    /* margin: 0px; */
    color: transparent;
}


@keyframes blink {
    0%{
        text-shadow: 0px 4px 26px #c582da;
    }
    50%{
        text-shadow: none;
    }
    100%{
        text-shadow: 0px 4px 26px #c582da;
    }
}

.m {
    width: 150px !important;
    top: 35px;
    position: absolute;
    margin-left: 50%;
    right: 50px;
    animation: float 6s ease-in-out infinite;
}


@media(min-width:800px){
    .m{
        width: 21%;
        top: 24%;

    }
}
@keyframes float {
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0px);
    }
}

#object2 {
    width: 100px;
    height: 100px;
    background: url(./images/ufo.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 200;
    animation: animation2 100s infinite;
}
@keyframes animation2 {
    0%{
        left: 0;
        top: 100;
    }
    25%{
        left: 1000px;
        top: 200px;
        width: 75px;
        transform: rotate3d(0, 0, 1, 45deg);
    }
    50%{
        left: 1000px;
        top: 500px;
        width: 50px;
    }
    75%{
        left: 2000px;
        top: 1000px;
        width: 20px;
    }
    100%{
        left: 0;
        width: 10px;
        top: 1000;
        transform: rotate3d(0, 0, 1, -45deg);
    }
}
#main{
    overflow:hidden;
    margin-top:150px;
}

.p-3 {
    /* position: absolute; */
    width: 729px;
    margin-top:5%;
    /* margin-left: 0; */
    
    animation: rotate 50s linear infinite;
    z-index:0;
}
@media (min-width: 700px) {
    .p-3{
    margin-top: 89%;
    margin-left: -112%;
        
    }
    

}
@media (min-width: 1080px) {
.jscop{
    margin-bottom: 25%;

}
}


@media (max-width: 1080px) {
    
    #home{
        height:75vh;
    }
    
    .landing__Heading{
        font-size: 6rem;
    }
   

}
#pulse:hover,
#pulse:focus {
  -webkit-animation: pulse 1s;
          animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
#pulse {
    --color: #ef6eae;
    --hover: #ef8f6e;
  }
@media (max-width: 768px) {
    
    #home{
        height:105vh;

    }
    .jscop{
        /* margin-bottom: 65%; */
    }
    .landing__Heading{
        font-size: 6rem;
        line-height: 90px;
    }
    .dates{
        margin-top: 12%;
    }
    
   

}

@media (max-width: 576px) {
    .m{
        margin-top:-201px;
        width:154px;
        top:312px;
    }
    .p-1{
        margin-top:50px;
    }
    .p-3{
        margin-top:-30%;
    }
    #home{
        height:110vh;
    }
    #main{
        margin-top:176px;
    }
    .jscop{
        margin-bottom:47%;
        max-height:50vh
    }
    .dates{
        margin-top: 12%;
    }

}


@media (max-width: 399px) {
    
    #home{
        height:165vh;
    }
    .jscop{
        margin-bottom: 0%;
    }
   

}


#layout { 
    /* color:transparent;  */
  
    /* background-image: url( https://www.manufosela.es/shooting_stars/stars_5.png); */
    /* background-repeat: repeat-x, repeat-x repeat-y; */
    transform:translate3D(0em, 0em, 0);
    animation: stars 21s ease;
    padding-bottom: 50px; 
    
  }
  .stars {
    z-index: 0;
    position: absolute;
    transform-style: preserve-3d;
  }
  
